import { TriangleDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  GridItem,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiVenue, ApiVenueSummary } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { HoursRange } from "../../../../components/dates/HoursRage";
import {
  DEFAULT_LOCALE_DATE_OPTS,
  LocaleDate,
} from "../../../../components/dates/LocaleDate";
import { StyledDatePicker } from "../../../../components/inputs/StyledDatePicker";
import { EventOccurrenceUpdateProps } from "../../../../store/events/event-details.slice";
import { compareTwoDates } from "../../../../utils/datesValidation";
import { filterAllowedDays } from "../../../../utils/filterAllowedDays";
import { filterAllowedTime } from "../../../../utils/filterAllowedTime";
import { getNearestVenueDate } from "../../../../utils/getNearestVenueDate";
import { getVenueTimes } from "../../../../utils/getVenueTimes";
import {
  addTwoHoursToEndDate,
  subsTwoHoursToEndDate,
  updateTimes,
} from "../../../../utils/updateTimes";
import { ConflictAlert } from "../form-components/ConflictsAlert";
import { LimitVenueInfo } from "../form-components/LimitVenueInfo";
import { UpdateLocalTimes } from "./EventDate";

const TIME_FORMAT = "h:mmaa";
const BORDER_MOBILE = "solid 1px #A0AEC0";

type DateTimeElements = {
  occurrence: EventOccurrenceUpdateProps;
  isSingle?: boolean;
  cleanIsCheckandCheckAll: (cleanCheckAll?: boolean) => void;
  updateSingleOccurrenceDates: (params: UpdateLocalTimes) => void;
  limitByVenue?: boolean;
  originalVenue?: ApiVenueSummary;
  isInactive?: boolean;
};
export const ChangeDateTime: FC<DateTimeElements> = ({
  isSingle,
  occurrence,
  cleanIsCheckandCheckAll,
  updateSingleOccurrenceDates,
  limitByVenue = false,
  originalVenue,
  isInactive,
}) => {
  const {
    start: dateStart,
    end: dateFinish,
    id: occurrenceId,
    conflicts,
  } = occurrence;
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [start, setStart] = useState<Date | null>(new Date(dateStart));
  const [startHour, setStartHour] = useState<Date | null>(new Date(dateStart));
  const [endHour, setEndHour] = useState<Date | null>(new Date(dateFinish));
  const [conflict, setConflict] = useState(conflicts);
  const [venueStart, setVenueStart] = useState<Date | null>(null);
  const [venueEnd, setVenueEnd] = useState<Date | null>(null);

  const boxBgColor = useColorModeValue(
    ["gray.50", "gray.50", "transparent"],
    "transparent"
  );
  const errorColor = useColorModeValue("red.500", "red.300");
  const { currentAccount, apiClient } = useAuthentication();

  const hasErrors = useMemo(() => {
    if (!startHour || !endHour) return false;
    return compareTwoDates(startHour, endHour);
  }, [endHour, startHour]);

  const handleOnSaveLocalState = useCallback(() => {
    if (!start || !startHour || !endHour) return;
    const newStartDate = updateTimes(startHour, start, false, false);
    const newEndDate = updateTimes(endHour, start, false, false);
    updateSingleOccurrenceDates({
      startHour: newStartDate,
      endHour: newEndDate,
      occurrenceId,
      conflicts: conflict,
      selectedDate: start,
    });
    onClose();
  }, [
    conflict,
    endHour,
    occurrenceId,
    onClose,
    start,
    startHour,
    updateSingleOccurrenceDates,
  ]);

  const checkConflicts = useCallback(async () => {
    if (!start || !startHour || !endHour || !isOpen) return;
    const newStartDate = updateTimes(startHour, start, false, false);
    const newEndDate = updateTimes(endHour, start, false, false);

    const occurrenceToCheck = {
      id: occurrence.id,
      start: newStartDate.toISOString(),
      end: newEndDate.toISOString(),
      spaces: occurrence.spaces.map((item) => item.id),
      venue: occurrence.venue.id,
    };

    const [first] = await apiClient.checkEventConflicts(currentAccount.id, [
      occurrenceToCheck,
    ]);
    setConflict(first);
  }, [
    apiClient,
    currentAccount.id,
    endHour,
    isOpen,
    occurrence.id,
    occurrence.spaces,
    occurrence.venue.id,
    start,
    startHour,
  ]);

  const handleOnChangeStartDate = useCallback(
    (value: Date | null) => {
      if (!value) {
        setStartHour(value);
        return;
      }

      setStartHour(value);

      if (endHour) {
        const newEndDate = addTwoHoursToEndDate(value);
        if (
          limitByVenue &&
          venueEnd &&
          newEndDate.getTime() > venueEnd.getTime()
        ) {
          setEndHour(venueEnd);
          return;
        }
        setEndHour(newEndDate);
      }
    },
    [endHour, limitByVenue, venueEnd]
  );

  const handleOnChangeEndDate = useCallback(
    (value: Date | null) => {
      setEndHour(value);
      if (!value) {
        return;
      }

      if (startHour) {
        const newStartDate = subsTwoHoursToEndDate(value);
        if (
          limitByVenue &&
          venueStart &&
          newStartDate.getTime() < venueStart.getTime()
        ) {
          setStartHour(venueStart);
          return;
        }
        if (value.getTime() < startHour.getTime()) setStartHour(newStartDate);
      }
    },
    [limitByVenue, startHour, venueStart]
  );

  const filterDay = useCallback(
    (date: Date) => {
      return filterAllowedDays({ date, venue: occurrence.venue });
    },
    [occurrence.venue]
  );

  const setAllowedTime = useCallback(
    (date: Date, setHours: boolean) => {
      const venueTimes = getVenueTimes(date, occurrence.venue);
      if (!venueTimes) return;
      const newDate = date ? date : start ? start : new Date();

      const {
        venueStartHour,
        venueStartMinutes,
        venueEndHour,
        venueEndMinutes,
      } = venueTimes;

      const newDateCopy = new Date(newDate);
      newDateCopy.setHours(venueStartHour);
      newDateCopy.setMinutes(venueStartMinutes);
      setVenueStart(newDateCopy);
      setHours && setStartHour(newDateCopy);

      const endDateCopy = new Date(newDate);
      endDateCopy.setHours(venueEndHour);
      endDateCopy.setMinutes(venueEndMinutes);
      setVenueEnd(endDateCopy);
      setHours && setEndHour(endDateCopy);
    },
    [occurrence.venue, start]
  );

  const filterTime = useCallback(
    (time: Date) => {
      return filterAllowedTime(time, venueStart, venueEnd);
    },
    [venueStart, venueEnd]
  );

  const handleOnChangeDateTime = useCallback(
    (date: Date | null) => {
      setStart(date);
      limitByVenue && date && setAllowedTime(date, true);
    },
    [limitByVenue, setAllowedTime]
  );

  const handleOnChangeVenue = useCallback(() => {
    if (!originalVenue) return;
    const date = getNearestVenueDate(occurrence.venue as ApiVenue);
    setStart(date);
    setAllowedTime(date, true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [occurrence.venue, originalVenue]);

  useEffect(() => {
    limitByVenue && handleOnChangeVenue();
  }, [handleOnChangeVenue, limitByVenue]);

  useEffect(() => {
    limitByVenue && setAllowedTime(start ? start : new Date(), false);
  }, [setAllowedTime, start, limitByVenue]);

  useEffect(() => {
    isOpen && cleanIsCheckandCheckAll(isSingle ? true : undefined);
  }, [isOpen, cleanIsCheckandCheckAll, isSingle]);

  useEffect(() => {
    checkConflicts();
  }, [checkConflicts]);

  return (
    <Popover
      isOpen={isOpen}
      onOpen={!isInactive ? onOpen : undefined}
      onClose={onClose}
    >
      <PopoverTrigger>
        <Box
          w={["100%", "100%", "45%"]}
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          alignItems="center"
          bgColor={boxBgColor}
          p={[4, 4, 0]}
          padding={[2, 2, "inherit"]}
          border={[BORDER_MOBILE, BORDER_MOBILE, "inherit"]}
          borderRadius={5}
          mb={[3, 3, "inherit"]}
          cursor={!isInactive ? "pointer" : undefined}
          justifyContent={["space-between", "space-between", "inherit"]}
        >
          <Box
            display={["block", "block", "flex"]}
            flexDirection={["row", "row", "column"]}
            w={["calc(100% - 30px)", "calc(100% - 30px)", "175px"]}
          >
            <LocaleDate
              date={dateStart}
              textProps={{ fontWeight: "bold" }}
              options={{ ...DEFAULT_LOCALE_DATE_OPTS }}
            />
            <HoursRange
              textBefore="at"
              start={dateStart}
              end={dateFinish}
              textProps={{ ml: [2, 2, 0] }}
            />
          </Box>
          {!isInactive && (
            <Icon as={TriangleDownIcon} w="30px" boxSize={3} ml={2} />
          )}
        </Box>
      </PopoverTrigger>

      {isOpen && (
        <PopoverContent py={2}>
          <PopoverBody>
            <Box w="100%">
              <Grid templateColumns="repeat(12, 1fr)" gap={5}>
                <GridItem colSpan={12}>
                  <Collapse
                    animateOpacity
                    unmountOnExit
                    in={conflict.hasConflict}
                  >
                    <>
                      <ConflictAlert
                        includeDescription={false}
                        alertTitleProps={{ fontWeight: "normal" }}
                        title="Please choose another date or time"
                      />
                    </>
                  </Collapse>
                </GridItem>

                <GridItem colSpan={12}>
                  <Text py={2}>Change Date/Time</Text>
                  <StyledDatePicker
                    value={start}
                    name="start"
                    showCustomLocaleInput
                    onChange={(date) => {
                      handleOnChangeDateTime(date);
                    }}
                    filterDate={limitByVenue ? filterDay : undefined}
                  />
                </GridItem>

                <GridItem colSpan={6}>
                  <Text py={2}>Event starts at</Text>
                  <StyledDatePicker
                    value={startHour}
                    name="startHour"
                    showTime
                    showTimeOnly
                    format={TIME_FORMAT}
                    className="hourPicker"
                    onChange={handleOnChangeStartDate}
                    hasError={hasErrors}
                    filterTime={limitByVenue ? filterTime : undefined}
                  />
                </GridItem>

                <GridItem colSpan={6} pb={2}>
                  <Text py={2}>Event ends at</Text>
                  <StyledDatePicker
                    value={endHour}
                    name="endHour"
                    showTime={true}
                    showTimeOnly={true}
                    format={TIME_FORMAT}
                    className="hourPicker"
                    onChange={handleOnChangeEndDate}
                    hasError={hasErrors}
                    filterTime={limitByVenue ? filterTime : undefined}
                  />
                </GridItem>

                {hasErrors && (
                  <GridItem colSpan={12} mt={-8}>
                    <Text color={errorColor} fontSize="sm">
                      Start time must be less than end time
                    </Text>
                  </GridItem>
                )}

                {limitByVenue && (
                  <GridItem colSpan={12}>
                    <LimitVenueInfo />
                  </GridItem>
                )}

                <GridItem colSpan={12}>
                  <Divider />
                </GridItem>

                <GridItem
                  display="flex"
                  colSpan={12}
                  justifyContent="space-between"
                >
                  <Button
                    variant="outline"
                    borderColor="blue.500"
                    borderRadius={5}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    colorScheme="blue"
                    borderRadius={5}
                    onClick={handleOnSaveLocalState}
                    disabled={hasErrors}
                  >
                    Save
                  </Button>
                </GridItem>
              </Grid>
            </Box>
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  );
};
