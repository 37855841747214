import { addHours, subHours } from "date-fns";

export const updateTimes = (
  date: Date | string,
  times: Date | string,
  updateHours: boolean = true,
  updateMinutes: boolean = true
) => {
  const value = new Date(times);
  const newHour = value.getHours();
  const newMinutes = value.getMinutes();

  const newValue = new Date(date);
  updateHours && newValue.setHours(newHour);
  updateMinutes && newValue.setMinutes(newMinutes);

  return newValue;
};

export const addTwoHoursToEndDate = (start: Date) => {
  const hours = start.getHours();

  const MAX_HOUR = 23;
  const MAX_MINUTES = 59;
  if (hours >= 22) {
    const newEnd = new Date(start);
    newEnd.setHours(MAX_HOUR);
    newEnd.setMinutes(MAX_MINUTES);
    return newEnd;
  }

  const newEnd = addHours(start, 2);
  return newEnd;
};

export const subsTwoHoursToEndDate = (end: Date) => {
  const hours = end.getHours();

  const MIN_HOUR = 0;
  const MIN_MINUTES = 0;
  if (hours <= 1) {
    const newStart = new Date(end);
    newStart.setHours(MIN_HOUR);
    newStart.setMinutes(MIN_MINUTES);
    return newStart;
  }

  const newStart = subHours(end, 2);
  return newStart;
};
